<template>
  <div id="app" class="min-h-screen w-screen">
    <router-view />
  </div>
  
</template>
<script>
  export default {
    watch: {
      // Set meta tags
      $route(to) {
        this.setMeta(to.meta);
      },
    },
    methods: {
      setMeta(meta) {
        document.title = meta.title;
        if(meta.title === undefined)
        {
          document.title = 'Complaia App';
        }
      }
    }
  }
</script>
<style>

body
{
  overflow-x: hidden;
}

</style>

