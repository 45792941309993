import { createStore } from 'vuex'
import { auth, db} from '@/firebase/gfbconf'
import axios from 'axios';

export default createStore({
  state: {
    notification: {
      show: false,
      head: "",
      subheader: "",
      success: true,
    },
    internetConnection: true,
    systemData: null,
    user: null,
    url: "/dashboard/feed",
    userSubscription: null,
    userData: null,
    authIsReady: false,
    globalLoader: {
      show: false,
      head: "Loading..",
      subheader: "Do not close this tab."
    },
    authData: null
  },
  mutations: {
    setNotification(state, notification)
    {
      state.notification = notification;
      setTimeout(()=>
      {
        state.notification = {
          show: false,
          head: "",
          subheader: "",
          success: true,
        }
      },3000)
    },
    setUrl(state, url)
    {
      state.url = url
    },
    setUserSubscription(state,subscription)
    {
      state.userSubscription = subscription;
    },
    // Set user to Vuex store
    setUser(state, userData)
    {
      state.user = userData;
    },
    updateInternetConnectionStatus(state, status)
    {
      state.internetConnection = status;
    },
    setUserCredentials(state, userData)
    {
      state.userData = userData;
    },
    setAuthIsReady(state,payload)
    {
      state.authIsReady = payload;
    },
    // reset global loader
    resetGlobalLoader(state)
    {
      state.globalLoader = {
        show: false,
        head: "Loading..",
        subheader: "Do not close this tab."
      }
    },
    setGlobalLoader(state,context)
    {
      state.globalLoader = context;
    },
    setSystemData(state,context)
    {
      state.systemData = context;
    },
    setAuthData(state,context)
    {
      state.authData = context;
    }

  },
  actions: {
    //login new user
    async login(context,payload)
    {
      const signIn = await auth.signInWithEmailAndPassword(payload.email, payload.password);
      if (signIn) {
        context.commit('setGlobalLoader',{
          show: true,
          head: "Checking your subscription..",
          subheader: `Do not close this tab.`,
          success: false
      });
        const userCredentials = await db.collection("UsersData").doc(signIn.user.uid).get();
        context.commit('setUserCredentials', userCredentials.data());
        const systemInfo = await context.state.userData.system.ref.get();
        context.commit('setSystemData', systemInfo.data());
        context.commit('setUser', signIn.user);
        if(payload.firstLogin !== true) {
        const result = await axios.post("https://europe-central2-complaia.cloudfunctions.net/api/check/subscription", {
          systemRef: context.state.userData.system.id,
          userId: context.state.user.uid,
        })
          if (result.data.activeSubscription === true) {
            context.commit("setUserSubscription", result.data.subscription);
            if(context.state.userData.previousPlan !== undefined && result.data.subscription.items[0].price.id !== undefined && context.state.userData.previousPlan !== result.data.subscription.items[0].price.id && context.state.userData.systemCreator) {
              context.state.globalLoader.head = "You changed your plan"
              context.state.globalLoader.subheader = "We're adjusting system settings"

              let planSettings = {
                imageSizePerFileMb: eval(result.data.subscription.items[0].price.product.metadata.imageSizePerFileMb),
                imagesPerComplaint: eval(result.data.subscription.items[0].price.product.metadata.imagesPerComplaint),
                pdfUpload: eval(result.data.subscription.items[0].price.product.metadata.pdfUpload),
                userAmount: eval(result.data.subscription.items[0].price.product.metadata.userAmount),
                planName: result.data.subscription.items[0].price.id
              }
              const checkSystemSettings = await axios.post("https://europe-central2-complaia.cloudfunctions.net/api/check/systemSettings", {
                systemId: context.state.userData.system.id,
                userId: context.state.user.uid,
                currentPlan: planSettings,
                previousPlan: context.state.userData.previousPlan,
              })
              context.state.userData.alert.description = checkSystemSettings.data.description
              context.state.userData.alert.show = checkSystemSettings.data.show
            }
            context.commit("setUrl", "/dashboard/feed");
          } else if (result.data.noSubscription !== undefined && result.data.noSubscription) {
            context.commit("setUrl", `/error?type=noSubscription&stripeLink=${window.btoa(result.data.stripeLink)}`);
          } else if (!context.state.userData.systemCreator) {
            context.commit("setUrl", `/error?type=SubscriptionExpired&email=${result.data.creatorEmail}`);
          } else if (context.state.userData.systemCreator) {
            context.commit("setUrl", `/selectplanexpired?currentplan=${result.data.currentPlan}`);
          }
        }
          
      } else {
        throw new Error("Could not complete login action.")
      }
    },
    // logout user;
    async logout(context)
    {
      context.state.authIsReady = false;
      await auth.signOut();
      context.commit('setUser', null);
    },
    async reset(context, payload) {
      const result = await axios.post("https://europe-central2-complaia.cloudfunctions.net/api/sendEmail", {
        attendantEmail: 'no-reply@complaia.com',
        to: payload.email,
        messageType: "passwordReset",
        payload: {}
      })
      if(result) {
        // console.log(result);
        context.commit('setAuthData', result.data);
      } else {
        context.commit('setNotification', {
          show: true,
          head: "Failure.",
          subheader: `Password reset link not sent. Please try again later.`,
          success: false
        });
        throw new Error("Could not complete login action.")
      }
    },
  },
  modules: {
  }
})
