import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Login',
    meta: { pageTitle: "Clients", title: 'Log in - Complaia' },
    component: () => import(/* webpackChunkName: "Home" */ '../views/Login.vue')
  },
  {
    path: '/resetpw',
    name: 'Reset',
    meta: { pageTitle: "Clients", title: 'Reset your Complaia Password - Complaia' },
    component: () => import(/* webpackChunkName: "Home" */ '../views/Reset.vue')
  },
  {
    path: '/success',
    name: 'Successfully',
    meta: { pageTitle: "Clients", title: 'Successfully changed - Complaia' },
    component: () => import(/* webpackChunkName: "Home" */ '../views/SuccessfullySent.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "Dashboard" */ '../views/Dashboard.vue'),
    children: [
      {
        path: 'profile',
        component: () => import(/* webpackChunkName: "about" */ '../views/Profile.vue'),
        meta: { pageTitle: "Profile and settings", title: 'Edit profile and settings - Complaia' }
      },
      {
        path: 'advanced',
        component: () => import(/* webpackChunkName: "about" */ '../views/AdvancedFeed.vue'),
        meta: { pageTitle: "Advanced Feed", title: 'Advanced Feed - Complaia' }
      },
      {
        path: 'modifyAttribute',
        component: () => import(/* webpackChunkName: "about" */ '../views/ModfiyAttributes.vue'),
        meta: { pageTitle: "Modify group of attributes", title: 'Edit group of attributes - Complaia' }
      },
      {
        path: 'customerportal',
        component: () => import(/* webpackChunkName: "about" */ '../views/CustomerPortalManager.vue'),
        meta: { pageTitle: "Customer Portal Manager", title: 'Customer Portal Manager - Complaia' }
      },
      {
        path: 'complaints/new',
        component: () => import(/* webpackChunkName: "CreateEditProduct" */ '../views/NewComplaint.vue'),
        meta: { pageTitle: "New Complaint", title: 'New Complaint - Complaia' }
      },
      {
        path: 'complaints/pending',
        component: () => import(/* webpackChunkName: "CreateEditProduct" */ '../views/PendingTickets.vue'),
        meta: { pageTitle: "Pending tickets", title: 'Tickets awaiting for review - Complaia' }
      },
      {
        path: 'complaints/pending/review',
        component: () => import(/* webpackChunkName: "CreateEditProduct" */ '../views/ReviewTicket.vue'),
        meta: { pageTitle: "Review ticket", title: 'Review ticket from online form - Complaia' }
      },
      {
        path: 'complaints/calendar',
        component: () => import(/* webpackChunkName: "CreateEditProduct" */ '../views/CalendarOfComplaints.vue'),
        meta: { pageTitle: "Calendar", title: 'Complaint Calendar - Complaia' }
      },
      {
        path: 'products',
        component: () => import(/* webpackChunkName: "Products" */ '../views/Products.vue'),
        meta: { pageTitle: "Product Manager", title: 'Manage products - Complaia' }
      },
      {
        path: 'product/add',
        component: () => import(/* webpackChunkName: "CreateEditProduct" */ '../views/ProductAdd.vue'),
        meta: { pageTitle: "Create product", title: 'Add new product - Complaia' }
      },
      {
        path: 'product/list',
        component: () => import(/* webpackChunkName: "CreateEditProduct" */ '../views/ProductsList.vue'),
        meta: { pageTitle: "All products", title: 'All products - Complaia' }
      },
      {
        path: 'product/categories',
        component: () => import(/* webpackChunkName: "Categories" */ '../views/Categories.vue'),
        meta: { pageTitle: "Categories", title: 'Product categories - Complaia' }
      },
      {
        path: 'product/view',
        component: () => import(/* webpackChunkName: "Categories" */ '../views/ProductViewmode.vue'),
        meta: { pageTitle: "Product Overview", title: 'View product - Complaia' }
      },
      {
        path: 'product/edit',
        component: () => import(/* webpackChunkName: "Categories" */ '../views/EditProduct.vue'),
        meta: { pageTitle: "Edit product", title: 'Edit product - Complaia' }
      },
      {
        path: 'product/warehouses',
        component: () => import(/* webpackChunkName: "Warehouses" */ '../views/Warehouses.vue'),
        meta: { pageTitle: "Warehouses", title: 'Product warehouses - Complaia' }
      },
      {
        path: 'product/manufacturers',
        component: () => import(/* webpackChunkName: "Manufacturers" */ '../views/Manufacturers.vue'),
        meta: { pageTitle: "Manufacturers", title: 'Product manufacturers - Complaia' }
      },
      {
        path: 'product/attributes',
        component: () => import(/* webpackChunkName: "Manufacturers" */ '../views/Attributes.vue'),
        meta: { pageTitle: "Groups of attributes", title: 'Product attributes - Complaia' }
      },
      {
        path: 'feed',
        component: () => import(/* webpackChunkName: "Feed" */ '../views/Feed.vue'),
        meta: { pageTitle: "Complaint Feed", title: 'Month feed and events - Complaia' }
      },
      {
        path: 'complaints',
        component: () => import(/* webpackChunkName: "Complaints" */ '../views/Complaints.vue'),
        meta: { pageTitle: "Complaint Manager", title: 'Complaint manager - Complaia' }
      },
      {
        path: 'complaints/list',
        component: () => import(/* webpackChunkName: "Complaints" */ '../views/ComplaintList.vue'),
        meta: { pageTitle: "Complaint List", title: 'List of complaints - Complaia' }
      },
      {
        path: 'complaints/edit',
        component: () => import(/* webpackChunkName: "Complaints" */ '../views/EditComplaint.vue'),
        meta: { pageTitle: "Manage complaint", title: 'Manage complaint - Complaia' }
      },
      {
        path: 'clients',
        component: () => import(/* webpackChunkName: "Clients" */ '../views/Clients.vue'),
        meta: { pageTitle: "Clients", title: 'Clients and contractors - Complaia' }
      },
      {
        path: 'clients/view/:id',
        component: () => import(/* webpackChunkName: "Clients" */ '../views/EditClient.vue'),
        meta: { pageTitle: "View client profile" }
      },
      {
        path: 'team',
        component: () => import(/* webpackChunkName: "Clients" */ '../views/Team.vue'),
        meta: { pageTitle: "Team Management" }
      },
      {
        path: 'analytics',
        component: () => import(/* webpackChunkName: "Feed" */ '../views/Analytics.vue'),
        meta: { pageTitle: "Analytics", title: 'Your system stats - Complaia' }
      },
      {
        path: 'chats',
        component: () => import(/* webpackChunkName: "Feed" */ '../views/chatInbox.vue'),
        meta: { pageTitle: "Chat inbox", title: 'List of complaint chats - Complaia' }
      },
      {
        path: 'chats/view',
        component: () => import(/* webpackChunkName: "Feed" */ '../views/chat.vue'),
        meta: { pageTitle: "Chat with customer", title: 'Complaint chat - Complaia' }
       },
       {
        path: 'permissions',
        component: () => import(/* webpackChunkName: "Feed" */ '../views/Permissions.vue'),
        meta: { pageTitle: "Permissions", title: 'Account permissions - Complaia' }
      },
        {
        path: 'support',
        component: () => import(/* webpackChunkName: "Feed" */ '../views/SupportForm.vue'),
        meta: { pageTitle: "Complaia support", title: 'Create support ticket' }
      },
    ]
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "Privacy" */ '../views/Privacy.vue'),
    children: [
      {
        path: 'copyright-policy',
        component: () => import(/* webpackChunkName: "Copyright" */ '../views/Copyright.vue'),
        meta: { pageTitle: "Copyright Policy", title: 'Copyright Policy - Complaia' }
      },
      {
        path: 'privacy-policy',
        component: () => import(/* webpackChunkName: "PrivacyPolicy" */ '../views/PrivacyPolicy.vue'),
        meta: { pageTitle: "Privacy Policy", title: 'Privacy Policy - Complaia' }
      },
      {
        path: 'refund-policy',
        component: () => import(/* webpackChunkName: "RefundPolicy" */ '../views/RefundPolicy.vue'),
        meta: { pageTitle: "Refund Policy", title: 'Refund Policy - Complaia' }
      },
      {
        path: 'terms_conditions',
        component: () => import(/* webpackChunkName: "TermsConditions" */ '../views/TermsConditions.vue'),
        meta: { pageTitle: "Terms Conditions", title: 'Terms Conditions - Complaia' }
      },
      {
        path: 'disclaimer',
        component: () => import(/* webpackChunkName: "Disclaimer" */ '../views/Disclaimer.vue'),
        meta: { pageTitle: "Disclaimer", title: 'Disclaimer - Complaia' }
      },
    ]
  },
  {
    path: '/selectplan',
    name: 'SelectPlan',
    meta: { pageTitle: "Clients", title: 'Select plan - Complaia' },
    component: () => import(/* webpackChunkName: "about" */ '../views/SelectPlans.vue')
  },
  {
    path: '/selectplanexpired',
    name: 'SelectPlanExpired',
    meta: { pageTitle: "Clients", title: 'Select plan - Complaia' },
    component: () => import(/* webpackChunkName: "about" */ '../views/SelectPlansExpired.vue')
  },
  {
    path: '/register',
    name: 'Register',
    meta: { pageTitle: "Clients", title: 'Create new account - Complaia' },
    component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue')
  },
  {
    path: '/invitation',
    name: 'Invitation',
    meta: { pageTitle: "Clients", title: 'Create new account to the existing system - Complaia' },
    component: () => import(/* webpackChunkName: "about" */ '../views/Invitation.vue')
  },
  {
    path: '/error',
    name: 'error',
    meta: { pageTitle: "Error", title: 'Error' },
    component: () => import(/* webpackChunkName: "SubscriptionEnded" */ '../views/Error404.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
